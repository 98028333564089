<template>
  <div id="center">
    <div class="wrapper">
      <div class="slash"></div>
      <div class="sides">
        <div class="side"></div>
        <div class="side"></div>
        <div class="side"></div>
        <div class="side"></div>
      </div>
      <div class="text">
        <div class="text--backing">coffeeo</div>
        <div class="text--left">
          <div class="inner">coffeeo</div>
        </div>
        <div class="text--right">
          <div class="inner">coffeeo</div>
        </div>
      </div>
    </div>
    <div class="loading">
      <span class="status online"></span>
      <span class="status invisible"></span>
      <span class="status idle"></span>
      <span class="status offline"></span>
    </div>
    <div class="navgation">
      <i v-for="(item, index) in navgation" @click="triggerPage(item.path)" :key="index" :class="item.icon">
        <i v-if="token && item.path === '/login'">
          个人信息
        </i>
        <i v-else-if="!token &&item.path === '/login' ">
          登录 / 注册
        </i>
        <i v-else>
          {{ item.name }}
        </i>
      </i>
    </div>
    <div>
      <!--            <a @click="toLogin">标一</a>-->
    </div>
    <div class="talk-method">
      <i class="iconfont icon-weixin"> : F2331351820</i>
      <!--      <i class="iconfont icon-qq"> : 2331351820 || <i class="iconfont icon-qq"> 前端群</i>: 700785102</i>-->
      <i class="iconfont icon-qq"> : 2331351820 </i>
    </div>
  </div>
</template>
<script>
export default {
  name: 'center',
  token: '',
  created() {
    this.token = localStorage.getItem('token')
  },
  data() {
    return {
      navgation: [
        {name: '文章列表', icon: 'iconfont icon-wenzhang', path: '/article'},
        {name: '云相册', icon: 'iconfont icon-xiangce', path: '/photos'},
        //{ name:' Demo',icon:'iconfont icon-anli', path:'/demo' },
        {name: '留言板', icon: 'iconfont icon-liuyan2', path: '/message'},
        {name: '登录', icon: 'iconfont icon-touxiang', path: '/login'},
        // { name:' Github',icon:'iconfont icon-GitHub', path:'https://github.com/Acmenlei' },
      ],
      vo: {
        username: 'xuhuan',
        password: 'xuhuan'
      }
    };
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     console.log('登录标一获取token')
  //     this.$axios.post('/sys/login', this.vo).then(res => {
  //       this.log = res.data.result
  //       const userPermission = JSON.stringify(this.log)
  //       console.log(userPermission)
  //       //   // 在页面加载完成后主页面向iframe发送请求
  //       window.frames[0] && window.frames[0].postMessage(userPermission, 'http://59.202.52.81:8080')
  //     })
  //     // window.onload = function () {
  //     // service({
  //     //   url: `/watch/login/social`,
  //     //   method: 'get',
  //     //   headers: {
  //     //     'Content-Type': 'application/json',
  //     //     'Access-Token': iopTokenService.getCurrentToken()
  //     //   }
  //     // }).then((res) => {
  //     //   this.log = res.data.result
  //     //   const userPermission = JSON.stringify(this.log)
  //     //   // 在页面加载完成后主页面向iframe发送请求
  //     //   window.frames[0].postMessage(userPermission, 'http://59.202.52.81:8080')
  //     // })
  //     // }
  //   })
  // },
  methods: {
    triggerPage(path) {
      if (path === 'https://github.com/Acmenlei') {
        window.open(path)
      }
      if (path === '/login') {
        if (localStorage.getItem('token')) {
          this.$router.push('/profile')
        } else {
          this.$router.push('/login')
        }
      } else {

        this.$router.push(path)
      }
    },
    async toLogin() {
      this.$axios.post('/sys/login', this.vo).then(res => {
        const token = res.data.result.token
        window.open('http://59.202.52.81:8080/#/yqgl/yqsh/Result?Access-Token=' + token + "&name=徐欢")
      })
      // window.open('http://localhost:3000/yqgl/yqsh/Result?Access-Token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MTkxNzE4MDYsInVzZXJuYW1lIjoiYWRtaW4ifQ.FQ-OZ8nWxwp-w6yhSl3dG1yPdNkCmaWGsa33KO3nisg')
    }
  },
}
</script>
<style lang="scss" scoped>
@import url('./lizi/trigger.css');

#center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .navgation,
  .talk-method {
    margin: 2rem 0;
    white-space: nowrap;

    i {
      color: white;
      margin: 0.3rem;
      font-size: 0.9rem;
      transition: all 0.6s;
      cursor: pointer;
    }

    i:hover {
      color: rgb(66, 245, 135);
      opacity: .8;
    }
  }

  .talk-method {
    margin: 0;
  }

  .loading {
    margin: 2rem 0 0 0;

    .status {
      position: relative;
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin: 10px;
    }

    .status.online, .status.online:before {
      background: #72ff7d;
    }

    .status.offline, .status.offline:before {
      background: #ff4242;
    }

    .status.invisible, .status.invisible:before {
      background: #42e5ff;
    }

    .status.idle, .status.idle:before {
      background: #ffda72;
    }

    .status:before {
      content: '';
      display: block;
      position: absolute;
      left: -5px;
      top: -5px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      animation: pulse 1.5s infinite ease-in;
    }

    @keyframes pulse {
      from {
        transform: scale(0.5);
        opacity: 1;
      }
      to {
        transform: scale(1.5);
        opacity: 0;
      }
    }
  }

  .container_img {
    margin-bottom: 2rem;
  }

  h3 {
    margin-bottom: 4rem;
    color: #f2f2f2;
    font-size: 2rem;
  }

  @media screen and(max-width: 568px) {
    .container_img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      overflow: hidden;
    }
    img {
      margin-left: -2rem;
    }
  }
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
  overflow: hidden;
}

</style>